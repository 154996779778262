import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import dialogPolyfill from 'dialog-polyfill'
import type { OverlayConfig } from './Overlay.shared.d'

const identifier = 'overlay'

const OverlayComponent: TComponentInit = (element) => {
	const config = JSON.parse(
		element.getAttribute('data-config') ?? '{}',
	) as OverlayConfig

	const triggers = document.querySelectorAll<HTMLElement>(
		`[data-dialog-open="${element.id}"]`,
	)
	const closeButtons =
		element.querySelectorAll<HTMLElement>(`[data-dialog-close]`)

	const open = (event: Event) => {
		event.preventDefault()
		;(element as HTMLDialogElement).showModal()
	}

	const close = () => {
		;(element as HTMLDialogElement).close()
	}

	const closeOnBackdropClick = (event: MouseEvent) => {
		const rect = element.getBoundingClientRect()
		const isInDialog =
			rect.top <= event.clientY &&
			event.clientY <= rect.top + rect.height &&
			rect.left <= event.clientX &&
			event.clientX <= rect.left + rect.width

		if (!isInDialog) {
			close()
		}
	}

	const onDialogClick = (event: MouseEvent) => {
		closeOnBackdropClick(event)
	}

	return {
		element,
		init: () => {
			dialogPolyfill.registerDialog(element as HTMLDialogElement)

			element.addEventListener('click', onDialogClick)

			triggers.forEach((trigger) => {
				trigger.addEventListener('click', open)
			})

			closeButtons?.forEach((button) => button.addEventListener('click', close))

			if (config.openCondition) {
				window.addEventListener('load', open)
			}
		},
		destroy: () => {
			element.removeEventListener('click', onDialogClick)

			triggers.forEach((trigger) => {
				trigger.removeEventListener('click', open)
			})

			closeButtons?.forEach((button) =>
				button.removeEventListener('click', close),
			)

			window.removeEventListener('load', open)
		},
	}
}

registerComponent(identifier, OverlayComponent)

export default {
	identifier,
	OverlayComponent,
}
